function checkCurentPage() {
  const pathname = window.location.pathname;
  const header = document.querySelector(".header");
  const headerBtn = document.querySelector(".header__btns button");

  switch (pathname) {
    case "/":
      //   header.classList.remove("white");
      break;
    default:
      header.classList.add("header--white");
      headerBtn.classList.remove("btn--transparent");
      headerBtn.classList.add("btn--normal");
      break;
  }
}

checkCurentPage();
