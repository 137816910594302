import enquire from "enquire.js";
import { overlayAdd, overlayRemove } from "../overlay/overlay";

const hamburger = document.querySelector(".hamburger--js");
const mobileMenu = document.querySelector(".mobmenu--js");
// const closeBtn = document.querySelector(".mobmenu__close-btn");
const page = document.querySelector("body");

function showMenu() {
  mobileMenu.classList.add("is-active");
  hamburger.classList.add("is-active");
  page.classList.add("is-hidden");
  //   overlayAdd();
}

function hideMenu() {
  mobileMenu.classList.remove("is-active");
  hamburger.classList.remove("is-active");
  page.classList.remove("is-hidden");
  //   overlayRemove();
}

const clickHandler = (event) => {
  event.preventDefault();

  if (mobileMenu.classList.contains("is-active")) {
    hideMenu();
  } else {
    showMenu();
  }
};

enquire.register("screen and (max-width: 1024px)", {
  // deferSetup: true,
  match() {
    hamburger.addEventListener("click", clickHandler);
    document.addEventListener("click", (event) => {
      if (event.target.dataset.backdrop === "overlay") {
        hideMenu();
      }
    });
    // closeBtn.addEventListener("click", hideMenu);
  },
  unmatch() {
    hamburger.removeEventListener("click", clickHandler);
    // closeBtn.removeEventListener("click", hideMenu);
  },
});
