const body = document.body;

export function overlayAdd() {
  const overlay = document.querySelector(".overlay");
  overlay.classList.add("is-active");
  body.classList.add("is-hidden");
  setTimeout(() => body.classList.add("is-animate"), 100);
}

export function overlayRemove() {
  const overlay = document.querySelector(".overlay");
  if (overlay) {
    overlay.classList.remove("is-active");
  }
  body.classList.remove("is-animate", "is-hidden");
}
