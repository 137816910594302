const setActiveMenuItem = () => {
  const currentPath = window.location.pathname;
  const menuItems = document.querySelectorAll(".mainmenu__item");
  const menuItemsMob = document.querySelectorAll(".mobmenu__item");

  menuItems.forEach(function (link) {
    const linkPath = link.querySelector(".mainmenu__link").getAttribute("href");

    if (currentPath.includes(linkPath)) {
      link.classList.add("is-active");
    }
  });

  menuItemsMob.forEach(function (link) {
    const linkPath = link.querySelector(".mobmenu__link").getAttribute("href");

    if (currentPath.includes(linkPath)) {
      link.classList.add("is-active");
    }
  });
};

const toggleSubMenu = () => {
  const mobmenuItems = document.querySelectorAll(".mainmenu__item");

  mobmenuItems.forEach((item) => {
    const submenu = item.querySelector(".submenu");
    if (submenu) {
      item.addEventListener("mouseenter", function () {
        item.classList.add("is-active");
        submenu.classList.add("is-active");
      });

      item.addEventListener("mouseleave", function () {
        item.classList.remove("is-active");
        submenu.classList.remove("is-active");
      });
    }
  });
};

document.addEventListener("DOMContentLoaded", function () {
  toggleSubMenu();
  setActiveMenuItem();
});
