const $accordionItems = document.querySelectorAll(".mobmenu__item");
const $accordionDropdown = document.querySelectorAll(".mobmenu__icon");

const toggleAccordion = (e) => {
  let target = e.currentTarget.closest(".mobmenu__item");
  const content = target.querySelector(".mobsubmenu");

  if (!target.classList.contains("mobmenu-expandable") && content) {
    $accordionItems.forEach((item) => {
      item.classList.remove("mobmenu-expandable");
    });
    target.classList.add("mobmenu-expandable");
    content.style.maxHeight = content.scrollHeight + "px";
  } else {
    target.classList.remove("mobmenu-expandable");
    content.style.maxHeight = null;
  }
};

if ($accordionDropdown) {
  $accordionDropdown.forEach((accordion) => {
    accordion.addEventListener("click", (e) => toggleAccordion(e));
  });
}
