import inputmask from "inputmask";
import { overlayAdd, overlayRemove } from "../overlay/overlay";

const phoneMask = document.querySelector(".js-phone-mask");
const modalBtns = document.querySelectorAll(".btn--callback-js");
const modalCloseBtns = document.querySelectorAll(".btn--close-js");
const formCallback = document.querySelector("#callbackModal");
const modalSuccess = document.querySelector("#modalSuccess");

if (phoneMask) {
  inputmask({
    mask: "+7 (999) 999-99-99",
    showMaskOnHover: false,
  }).mask(phoneMask);
}

export function openModalCallback(e) {
  if (!formCallback.classList.contains("is-active")) {
    overlayAdd();
    formCallback.classList.add("is-active");
  } else {
    overlayRemove();
    formCallback.classList.remove("is-active");
  }
}

export function closeModals(e) {
  overlayRemove();
  formCallback.classList.remove("is-active");
  modalSuccess.classList.remove("is-active");
}

if (modalBtns) {
  modalBtns.forEach((btn) => btn.addEventListener("click", (e) => openModalCallback(e)));
}

modalCloseBtns.forEach((btn) => btn.addEventListener("click", (e) => closeModals(e)));
